import './components/_1_preloader';
import './components/_1_joomla';
import './components/_1_totop-btn';
import './components/_1_reveal-effects';
import './components/_1_anim-menu-btn';
import './components/_2_flexi-header';
import './components/_1_table';
import './components/_1_modal-window';
import './components/_1_helpCenter';
import './components/_1_tabs';
import './components/_1_content-items';
import './components/_1_accordion';
import './components/_1_acmeticker';
import './components/_1_thq-form';
import './components/_1_alert';
import './components/_1_faqs-search';
import Isotope from 'isotope-layout';
// import "./components/_3_mega-site-navigation";

import FixedSideNav from './components/_1_fixed-sidenav';
import Swiper from 'swiper';
import { Navigation, Pagination } from 'swiper/modules';

const setFHeaderDropdown = () => {
    // Wählen Sie das erste Navigationspunkt-Element aus
    const navItem = document.querySelector('.f-header__link:first-child');

    // Ermitteln Sie den linken Abstand des Navigationspunktes zum linken Rand der Webseite
    const navItemOffset = navItem.getBoundingClientRect().left;

    // Setzen Sie den Wert der CSS-Variable
    document.documentElement.style.setProperty('--f-submenu-pl', `${navItemOffset}px`);
};

const moveBannerBox = () => {
    const banner = document.querySelector('.mod-banner');
    const bannerBox = document.querySelector('[data-element="banner-box"]');

    // get root css variable
    const root = document.documentElement;
    const rootStyles = getComputedStyle(root);
    const screenLg = rootStyles.getPropertyValue('--screen-lg');

    // remove px from variable
    const screenLgNum = screenLg.replace('px', '');

    if (banner && bannerBox) {
        if (window.innerWidth <= screenLgNum) {
            // move the banner box after the banner
            banner.insertAdjacentElement('afterend', bannerBox);
        } else {
            // check if the banner box is already in the banner
            if (banner.querySelector('[data-element="banner-box"]')) {
                return;
            }

            // move the banner box back to the original position
            banner.querySelector('.mod-banner__box-wrap').appendChild(bannerBox);
        }
    }
};

const openModal = (element) => {
    var event = new CustomEvent('openModal');
    element.dispatchEvent(event);
};

// init
(() => {
    // init isotope
    const grid = document.querySelector('.grid-items');

    if (grid) {
        const iso = new Isotope(grid, {
            itemSelector: '.grid-item',
            layoutMode: 'fitRows',
            transitionDuration: 0,
        });

        const checkIfEmpty = () => {
            const visibleItems = iso.getFilteredItemElements();
            const emptyMessage = document.querySelector('.empty-message');

            if (visibleItems.length === 0) {
                emptyMessage.style.display = 'block';
            } else {
                emptyMessage.style.display = 'none';
            }
        };

        const filterBtns = document.querySelectorAll('.filter-btn');
        const filterBtnsArr = Array.from(filterBtns);
        let activeFilters = [];

        filterBtnsArr.forEach((btn) => {
            btn.addEventListener('click', (e) => {
                e.preventDefault();
                const filterValue = btn.getAttribute('data-filter');

                if (activeFilters.includes(filterValue)) {
                    const index = activeFilters.indexOf(filterValue);
                    activeFilters.splice(index, 1);

                    btn.setAttribute('aria-selected', 'false');
                } else {
                    activeFilters.push(filterValue);

                    btn.setAttribute('aria-selected', 'true');
                }

                const filterString = activeFilters.join(', ');

                iso.arrange({ filter: filterString });

                checkIfEmpty();
            });
        });
    }

    // init fixed sidenav
    new FixedSideNav(document.querySelector('[data-fixed-sidenav]'));

    const newssliderSwiper = new Swiper('[data-element="newsslider"]', {
        slidesPerView: 1,
        autoHeight: true,
        modules: [Navigation, Pagination],
        loop: false,
        navigation: {
            nextEl: '.swiper__button-next.mod-articlesnews-slider',
            prevEl: '.swiper__button-prev.mod-articlesnews-slider',
        },
        pagination: {
            el: '.swiper-news-pagination',
            type: 'bullets',
            clickable: true,
            renderBullet: function (index, className) {
                return (
                    '<div class="' +
                    className +
                    ' border-white bg-transparent border-2 w-6 h-6 rounded-full flex items-center justify-center opacity-100"><span class="rounded-full bg-transparent w-3 h-3"></span></div>'
                );
            },
        },
    });

    const sliderSwiper = new Swiper('[data-element="slider"]', {
        slidesPerView: 1,
        modules: [Navigation, Pagination],
        loop: false,
        grabCursor: true,
        autoHeight: true,
        navigation: {
            nextEl: '.swiper__button-next.mod-slider',
            prevEl: '.swiper__button-prev.mod-slider',
        },
        pagination: {
            el: '.swiper-slider-pagination',
            type: 'bullets',
            clickable: true,
            renderBullet: function (index, className) {
                return (
                    '<div class="' +
                    className +
                    ' border-secondary-500 md:border-white bg-transparent border-2 w-6 h-6 rounded-full flex items-center justify-center opacity-100"><span class="rounded-full bg-transparent w-3 h-3"></span></div>'
                );
            },
        },
    });

    const karriereSliders = document.querySelectorAll('[data-element="sliderkarriere"]');

    if (karriereSliders.length > 0) {
        karriereSliders.forEach((slider) => {
            new Swiper(slider, {
                slidesPerView: 1,
                modules: [Navigation, Pagination],
                loop: false,
                grabCursor: true,
                spaceBetween: 30,
                autoHeight: true,
                navigation: {
                    nextEl: slider.parentElement.querySelector(
                        '.swiper__button-next.mod-sliderkarriere',
                    ),
                    prevEl: slider.parentElement.querySelector(
                        '.swiper__button-prev.mod-sliderkarriere',
                    ),
                },
                pagination: {
                    el: slider.parentElement.querySelector('.swiper-sliderkarriere-pagination'),
                    type: 'bullets',
                    clickable: true,
                    renderBullet: function (index, className) {
                        return (
                            '<div class="' +
                            className +
                            ' border-white bg-transparent border-2 w-6 h-6 rounded-full flex items-center justify-center opacity-100"><span class="rounded-full bg-transparent w-3 h-3"></span></div>'
                        );
                    },
                },
            });
        });
    }

    const contentitemsliderSwiper = new Swiper('[data-element="contentitemslider"]', {
        slidesPerView: 1.5,
        autoHeight: false,
        spaceBetween: 30,
        modules: [Navigation, Pagination],
        loop: false,
        breakpoints: {
            768: {
                slidesPerView: 2.5,
                spaceBetween: 15,
            },
            1024: {
                slidesPerView: 3.5,
            },
        },
        navigation: {
            nextEl: '.swiper__button-next.mod-contentitemslider-slider',
            prevEl: '.swiper__button-prev.mod-contentitemslider-slider',
        },
        pagination: {
            el: '.swiper-contentitemslider-pagination',
            type: 'bullets',
            clickable: true,
            renderBullet: function (index, className) {
                return (
                    '<div class="' +
                    className +
                    ' border-white bg-transparent border-2 w-6 h-6 rounded-full flex items-center justify-center opacity-100"><span class="rounded-full bg-transparent w-3 h-3"></span></div>'
                );
            },
        },
    });

    const ablesungslider1Swiper = new Swiper('[data-element="ablesungslider1"]', {
        slidesPerView: 1.5,
        autoHeight: false,
        spaceBetween: 30,
        modules: [Navigation, Pagination],
        loop: false,
        breakpoints: {
            768: {
                slidesPerView: 2.5,
                spaceBetween: 15,
            },
            1024: {
                slidesPerView: 3.5,
            },
        },
        navigation: {
            nextEl: '.swiper__button-next.ablesungslider1',
            prevEl: '.swiper__button-prev.ablesungslider1',
        },
        pagination: {
            el: '.swiper-ablesungslider1-pagination',
            type: 'bullets',
            clickable: true,
            renderBullet: function (index, className) {
                return (
                    '<div class="' +
                    className +
                    ' border-primary-500 bg-transparent border-2 w-6 h-6 rounded-full flex items-center justify-center opacity-100"><span class="rounded-full bg-transparent w-3 h-3"></span></div>'
                );
            },
        },
    });

    const ablesungslider2Swiper = new Swiper('[data-element="ablesungslider2"]', {
        slidesPerView: 1.5,
        autoHeight: false,
        spaceBetween: 30,
        modules: [Navigation, Pagination],
        loop: false,
        breakpoints: {
            768: {
                slidesPerView: 2.5,
                spaceBetween: 15,
            },
            1024: {
                slidesPerView: 3.5,
            },
        },
        navigation: {
            nextEl: '.swiper__button-next.ablesungslider2',
            prevEl: '.swiper__button-prev.ablesungslider2',
        },
        pagination: {
            el: '.swiper-ablesungslider2-pagination',
            type: 'bullets',
            clickable: true,
            renderBullet: function (index, className) {
                return (
                    '<div class="' +
                    className +
                    ' border-primary-500 bg-transparent border-2 w-6 h-6 rounded-full flex items-center justify-center opacity-100"><span class="rounded-full bg-transparent w-3 h-3"></span></div>'
                );
            },
        },
    });

    const ablesungslider3wiper = new Swiper('[data-element="ablesungslider3"]', {
        slidesPerView: 1.5,
        autoHeight: false,
        spaceBetween: 30,
        modules: [Navigation, Pagination],
        loop: false,
        breakpoints: {
            768: {
                slidesPerView: 2.5,
                spaceBetween: 15,
            },
            1024: {
                slidesPerView: 3.5,
            },
        },
        navigation: {
            nextEl: '.swiper__button-next.ablesungslider3',
            prevEl: '.swiper__button-prev.ablesungslider3',
        },
        pagination: {
            el: '.swiper-ablesungslider3-pagination',
            type: 'bullets',
            clickable: true,
            renderBullet: function (index, className) {
                return (
                    '<div class="' +
                    className +
                    ' border-primary-500 bg-transparent border-2 w-6 h-6 rounded-full flex items-center justify-center opacity-100"><span class="rounded-full bg-transparent w-3 h-3"></span></div>'
                );
            },
        },
    });

    const installateursliderSwiper = new Swiper('[data-element="installateurslider"]', {
        slidesPerView: 1,
        spaceBetween: 20,
        modules: [Navigation, Pagination],
        loop: false,
        breakpoints: {
            768: {
                slidesPerView: 2,
            },
            1024: {
                slidesPerView: 4,
            },
        },
        navigation: {
            nextEl: '.swiper__button-next.mod-installateurverzeichnis',
            prevEl: '.swiper__button-prev.mod-installateurverzeichnis',
        },
        pagination: {
            el: '.swiper-installateurverzeichnis-pagination',
            type: 'bullets',
            clickable: true,
            renderBullet: function (index, className) {
                return (
                    '<div class="' +
                    className +
                    ' border-dark-500 bg-transparent border-2 w-6 h-6 rounded-full flex items-center justify-center opacity-100"><span class="rounded-full bg-transparent w-3 h-3"></span></div>'
                );
            },
        },
    });

    // move modals to body
    const divsToMove = document.querySelectorAll('.modal');
    const body = document.getElementsByTagName('body')[0];

    Array.from(divsToMove).forEach((div) => {
        div.setAttribute('data-is-moved', 'true');
        body.appendChild(div);
    });

    // Scroll to finder form
    const finderForm = document.querySelector('.com-finder');
    if (finderForm) {
        finderForm.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
            inline: 'nearest',
        });
    }

    // sidebar toggle for mobile
    const sidebarToggle = document.querySelector('.sidebar-toggle');
    const sidebar = document.querySelector('[data-fixed-sidenav]');

    if (sidebarToggle && sidebar) {
        sidebarToggle.addEventListener('click', () => {
            sidebar.classList.toggle('hidden');
        });
    }

    // cookie banner link prevent default
    const cookieLink = document.querySelector('.cc-custom-revoke');
    if (cookieLink) {
        cookieLink.addEventListener('click', (event) => {
            event.preventDefault();
        });
    }

    // move banner box on mobile to the banner bottom
    moveBannerBox();
})();

window.addEventListener('DOMContentLoaded', (event) => {
    setTimeout(() => {
        setFHeaderDropdown();
    }, 0);

    // get all iframes with url from youtube and wrap them in a div
    const iframes = document.querySelectorAll("iframe[src^='https://www.youtube']");

    if (iframes) {
        const iframesArr = Array.from(iframes);

        iframesArr.forEach((iframe) => {
            const wrapper = document.createElement('div');
            wrapper.classList.add('aspect-w-16', 'aspect-h-8');

            // replace parent with wrapper
            iframe.parentElement.replaceWith(wrapper);
            wrapper.appendChild(iframe);
        });
    }

    // check the URL for #rueckruf
    if (window.location.href.includes('#rueckruf')) {
        const modal = document.querySelector('#modal-callback');
        openModal(modal);
    }
});

// resize
window.addEventListener('resize', () => {
    setFHeaderDropdown();

    // move banner box on mobile to the banner bottom
    moveBannerBox();
});
