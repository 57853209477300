(function () {
    class ContentItems {
        constructor(element) {
            this.element = element;
            this.contentItems = Array.from(document.querySelectorAll('.mod-contentitems'));

            this.init();

            Array.from(this.element.querySelectorAll('.swiper-slide')).map((item) => {
                item.addEventListener('click', (event) => {
                    event.preventDefault();

                    // Entfernen Sie das Attribut "aria-selected" von allen Elementen
                    Array.from(
                        this.element.querySelectorAll(".swiper-slide[aria-selected='true']"),
                    ).map((slide) => {
                        slide.removeAttribute('aria-selected');
                    });

                    // Fügen Sie das Attribut "aria-selected" dem geklickten Element hinzu
                    item.setAttribute('aria-selected', 'true');

                    const contentID = item.getAttribute('aria-controls');
                    if (contentID) {
                        this.contentItems.map((item) => {
                            item.style.display = 'none';
                        });

                        document
                            .querySelector(`[data-contentitem-id="${contentID}"]`)
                            .style.removeProperty('display');

                        const targetElement = element.parentElement.parentElement;
                        if (targetElement) {
                            const offset = 96;
                            const rect = targetElement.getBoundingClientRect();
                            const scrollPosition = window.scrollY + rect.top + rect.height + offset;

                            window.scrollTo({
                                top: scrollPosition,
                                behavior: 'smooth',
                            });
                        }
                    }
                });
            });
        }

        init = () => {
            if (this.contentItems.length > 0) {
                this.contentItems.map((item, index) => {
                    item.style.display = 'none';
                });
            }
        };
    }

    const contentItemSlider = document.querySelector("[data-element='contentitemslider']");

    if (contentItemSlider) {
        new ContentItems(contentItemSlider);
    }
})();
