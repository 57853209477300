(() => {
  class FaqsSearch {
    constructor(element) {
      this.element = element;
      this.defaultResults = this.element.parentElement.nextElementSibling;
      this.input = this.element.querySelector(".faqs-search__input");
      this.searchBtn = this.element.querySelector(".faqs-search__btn");
      this.resetBtn = this.element.querySelector(".faqs-search__reset");
      this.loader = this.element.querySelector(".faqs-search__results-loader");
      this.resultsList = this.element.parentElement.querySelector(
        ".faqs-search__results-list",
      );
      this.results = [];
      this.searchTerm = "";
      this.init();
    }

    init() {
      this.searchBtn.addEventListener("click", this.handleSearch);
      this.resetBtn.addEventListener("click", this.handleReset);
    }

    handleSearch = async (event) => {
      event.stopPropagation();
      event.preventDefault();

      this.searchTerm = this.input.value;
      this.loader.classList.remove("hidden");
      this.toggleButtons(true);

      try {
        const url =
          "index.php?option=com_ajax&module=faqs&method=get&format=json";
        const response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ search: this.searchTerm }),
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const resData = await response.json();
        if (resData.success) {
          this.handleSearchSuccess(resData);
        }
      } catch (error) {
        console.error("Error:", error);
        throw error;
      } finally {
        this.loader.classList.add("hidden");
        this.toggleButtons(false);
      }
    };

    handleSearchSuccess(resData) {
      if (resData.data.valid) {
        this.defaultResults.classList.add("hidden");
        this.resultsList.classList.remove("hidden");

        this.renderResults(resData.data.answers);
        new Accordion(this.resultsList);
      } else {
        this.showErrorMessage(resData.data.message);
      }
    }

    handleSearchFailure(message) {
      this.showErrorMessage(message);
    }

    showErrorMessage(message) {
      this.defaultResults.classList.add("hidden");
      this.resultsList.classList.remove("hidden");
      this.resultsList.innerHTML = `<p class="bg-blue-200 border border-blue-300/75 py-2 px-4 rounded-md text-dark-500">${message}</p>`;
    }

    toggleButtons(disabled) {
      this.searchBtn.disabled = disabled;
      this.resetBtn.disabled = disabled;
      const btnIcon = this.searchBtn.querySelector(".faqs-search__btn-icon");
      btnIcon.classList.toggle("hidden", disabled);
    }

    handleReset = (event) => {
      event.stopPropagation();
      event.preventDefault();

      this.reset();
    };

    reset() {
      this.defaultResults.classList.remove("hidden");
      this.resultsList.classList.add("hidden");
      this.input.value = "";
      this.resultsList.innerHTML = "";
    }

    renderResults(results) {
      const resultsJson = JSON.parse(results);
      this.resultsList.innerHTML = resultsJson
        .map((result) => this.renderResultItem(result))
        .join("");
    }

    renderResultItem(result) {
      return `
        <li class="accordion__item js-accordion__item">
          <button class="accordion__header bg-p-to-s-x rounded-bl-[36px] py-3 lg:py-5 px-5 lg:px-8 mb-4 js-tab-focus" type="button">
            <span class="text-lg lg:text-2xl text-white text-shadow w-[90%]">${result.question}</span>
            <svg class="icon h-[24px] w-[24px] inline-block text-inherit text-white leading-none shrink-0 accordion__icon-arrow-v2 no-js:is-hidden" viewBox="0 0 20 20">
              <g class="icon__group" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round">
                <line x1="3" y1="3" x2="17" y2="17" />
                <line x1="17" y1="3" x2="3" y2="17" />
              </g>
            </svg>
          </button>
          <div class="accordion__panel pt-1.5 lg:pt-2 px-5 lg:px-8 pb-5 lg:pb-8 js-accordion__panel">
            <div class="prose lg:prose-lg">
              ${result.answer}
            </div>
          </div>
        </li>`;
    }
  }

  const faqsSearch = document.querySelector(".faqs-search");
  if (faqsSearch) {
    new FaqsSearch(faqsSearch);
  }
})();
