(function () {
  class HelpCenter {
    constructor(element) {
      this.element = element;
      this.select = this.element.querySelector("[data-select]");
      this.helpCenterItems = this.element.querySelectorAll(
        "[data-helpcenter-item]",
      );

      this.init();

      this.select.addEventListener("change", (event) => {
        this.handleSelectChange(event);
      });
    }

    init = () => {
      this.helpCenterItems.forEach((item, idx) => {
        if (idx !== 0) {
          item.classList.add("!hidden");
        }
      });
    };

    handleSelectChange = (event) => {
      const selectedOption = event.target.options[event.target.selectedIndex];
      const selectedOptionValue = selectedOption.value;

      this.helpCenterItems.forEach((item) => {
        if (item.dataset.helpcenterItem === selectedOptionValue) {
          item.classList.remove("!hidden");
        } else {
          item.classList.add("!hidden");
        }
      });
    };
  }

  const helpCenter = document.querySelector("[data-element='helpcenter']");
  if (helpCenter) {
    new HelpCenter(helpCenter);
  }
})();
