(function () {
  class ThqForm {
    constructor(element) {
      this.element = element;
      this.steps = this.element.getElementsByClassName("step");
      this.stepNumbersContainer = document.getElementById("step-numbers");
      this.nextBtn = document.getElementById("next-button");
      this.prevBtn = document.getElementById("prev-button");
      this.form = document.getElementById("steps");
      this.submitBtn = document.getElementById("submit-button");
      this.currentIndex = 0;

      this.init();

      this.nextBtn.onclick = () => {
        if (
          this.currentIndex < this.steps.length - 1 &&
          this.areRequiredInputsFilled()
        ) {
          this.steps[this.currentIndex].classList.remove("active");
          this.stepNumbersContainer.children[
            this.currentIndex
          ].classList.remove("active");
          this.steps[++this.currentIndex].classList.add("active");
          this.stepNumbersContainer.children[this.currentIndex].classList.add(
            "active",
          );
          this.updateButtons();
        } else {
          alert("Bitte füllen Sie alle Pflichtfelder aus.");
        }
      };

      this.prevBtn.onclick = () => {
        if (this.currentIndex > 0) {
          this.steps[this.currentIndex].classList.remove("active");
          this.stepNumbersContainer.children[
            this.currentIndex
          ].classList.remove("active");
          this.steps[--this.currentIndex].classList.add("active");
          this.stepNumbersContainer.children[this.currentIndex].classList.add(
            "active",
          );
          this.updateButtons();
        }
      };

      this.form.onsubmit = async (e) => {
        e.preventDefault();

        const formData = new FormData(this.form);
        const overlay = this.element.querySelector("[data-element='loading']");
        overlay.style.display = "flex";
        this.submitBtn.disabled = true;
        this.submitBtn.style.paddingRight = "3rem";

        try {
          const res = await fetch(
            "/index.php?option=com_ajax&module=thqform&method=get&format=json",
            {
              method: "POST",
              headers: {
                "X-Requested-With": "XMLHttpRequest",
              },
              body: formData,
            },
          );

          const result = await res.json();

          if (result.success && result.data.success) {
            const message = `
                <div class="alert alert--is-visible bg-teal-600/80 text-white rounded-md p-4 mb-8 absolute text-[0.9375rem] [&.alert--is-visible]:static js-alert" role="alert">
                  <div class="flex items-center justify-between">
                    <div class="flex items-center">
                      <svg class="h-6 w-6 mr-2 text-teal-800 icon" viewBox="0 0 24 24" aria-hidden="true">
                        <g fill="currentColor">
                          <circle cx="12" cy="12" r="12" opacity=".2" style="isolation:isolate"></circle>
                          <path d="M9.5 17a1 1 0 0 1-.707-.293l-3-3a1 1 0 0 1 1.414-1.414L9.5 14.586l7.293-7.293a1 1 0 1 1 1.439 1.389l-.025.025-8 8A1 1 0 0 1 9.5 17z"></path>
                        </g>
                      </svg>
                      <p><strong class="font-bold">Erfolg:</strong> ${result.data.message}</p>
                    </div>
                    <button class="inline-flex shrink-0 ml-6 transition-all duration-300 hover:scale-110 js-alert__close-btn">
                      <svg class="icon block h-4 w-4 text-inherit fill-current leading-none shrink-0" viewBox="0 0 16 16">
                        <title>Close alert</title>
                        <g fill="currentColor">
                          <path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 3l10 10"></path>
                          <path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 3L3 13"></path>
                        </g>
                      </svg>
                    </button>
                  </div>
                </div>
              `;
            this.form.insertAdjacentHTML("beforebegin", message);
            overlay.style.display = "none";
            this.submitBtn.disabled = false;
            this.submitBtn.style.paddingRight = "0";
            this.form.remove();

            document.getElementsByClassName("js-alert__close-btn")[0].onclick =
              function () {
                document.getElementsByClassName("js-alert")[0].remove();
              };
          }

          if (!result.data.success && !result.success) {
            const message = `
            <div class="alert alert--is-visible bg-red-600/80 text-white rounded-md p-4 mb-8 absolute text-[0.9375rem] [&.alert--is-visible]:static js-alert" role="alert">
            <div class="flex items-center justify-between">
              <div class="flex items-center">
                <svg class="h-6 w-6 mr-2 text-red-800 icon" viewBox="0 0 24 24" aria-hidden="true">
                  <g fill="currentColor">
                    <circle cx="12" cy="12" r="12" fill-opacity=".2"></circle>
                    <path d="M12 15a1 1 0 0 1-1-1V5a1 1 0 0 1 2 0v9a1 1 0 0 1-1 1z"></path>
                    <circle cx="12" cy="18.5" r="1.5"></circle>
                  </g>
                </svg>

                <p><strong class="font-bold">Fehler:</strong> ${
                  result.data.message ? result.data.message : result.message
                }.</p>
              </div>

              <button class="inline-flex shrink-0 ml-6 transition-all duration-300 hover:scale-110 js-alert__close-btn">
                <svg class="icon block h-4 w-4 text-inherit fill-current leading-none shrink-0" viewBox="0 0 16 16">
                  <title>Close alert</title>
                  <g fill="currentColor">
                    <path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 3l10 10"></path>
                    <path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 3L3 13"></path>
                  </g>
                </svg>
              </button>
            </div>
          </div>
          `;
            this.form.insertAdjacentHTML("beforebegin", message);
            overlay.style.display = "none";
            this.submitBtn.disabled = false;
            this.submitBtn.style.paddingRight = "0";

            document.getElementsByClassName("js-alert__close-btn")[0].onclick =
              function () {
                document.getElementsByClassName("js-alert")[0].remove();
              };
          }
        } catch (err) {
          console.error(err);
        }
      };
    }

    init = () => {
      this.submitBtn.style.display = "none";

      for (let i = 0; i < this.steps.length; i++) {
        let stepNumber = document.createElement("span");
        stepNumber.innerHTML = i + 1;
        stepNumber.setAttribute("role", "step");
        stepNumber.setAttribute("aria-label", "Schritt " + (i + 1));
        this.steps[i].setAttribute("aria-labelledby", "step-" + (i + 1));
        this.steps[i].setAttribute("role", "tabpanel");
        this.stepNumbersContainer.appendChild(stepNumber);
      }

      this.steps[0].classList.add("active");
      this.stepNumbersContainer.children[0].classList.add("active");
    };

    updateButtons = () => {
      this.prevBtn.disabled = this.currentIndex === 0;

      if (this.currentIndex === this.steps.length - 1) {
        // Verstecken Sie den nextBtn und zeigen Sie den submitBtn
        this.nextBtn.style.display = "none";
        this.submitBtn.style.display = "block";
      } else {
        this.nextBtn.disabled = this.currentIndex === this.steps.length - 1;
        // Zeigen Sie den nextBtn und verstecken Sie den submitBtn
        this.nextBtn.style.display = "block";
        this.submitBtn.style.display = "none";
      }
    };

    areRequiredInputsFilled() {
      const inputs =
        this.steps[this.currentIndex].getElementsByTagName("input");
      for (let i = 0; i < inputs.length; i++) {
        if (inputs[i].hasAttribute("required") && !inputs[i].value) {
          return false;
        }
      }
      return true;
    }
  }

  const thqForm = document.querySelector("[data-element='thqForm']");
  if (thqForm) {
    new ThqForm(thqForm);
  }
})();
