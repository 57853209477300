// Init: new FixedSideNav(document.querySelector('.fixed-sidenav'));

class FixedSideNav {
  constructor(element) {
    this.element = element;
    this.toggleBtns = this.element.querySelectorAll(
      "[data-fixed-sidenav-toggle]",
    );
    this.initSideNav();
  }

  initSideNav() {
    this.toggleSideNav();
  }

  toggleSideNav() {
    Array.from(this.toggleBtns).forEach((btn) => {
      btn.addEventListener("click", (event) => {
        const prevSibling = event.currentTarget.previousElementSibling;

        prevSibling.setAttribute(
          "aria-hidden",
          prevSibling.getAttribute("aria-hidden") === "true" ? "false" : "true",
        );
      });
    });
  }
}

export default FixedSideNav;
